import { httpPost } from "@/api/request";
import { useStoreUser } from "@/store";

// 获取在线设备
export function GET_EQUIPMENT_ONLINE_LIST(params: object) {
    return httpPost("/pole/equipment/online", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
